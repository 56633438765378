// src/components/Dropdown.js

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { FaCopy } from 'react-icons/fa';
import NestedDropdown from './NestedDropdown';
import { useFloating, offset, flip, shift, autoUpdate } from '@floating-ui/react-dom';

const Dropdown = ({
  title,
  items,
  playHoverSound,
  playClickSound,
  copyToClipboard,
  isOpen,
  setOpenDropdown,
}) => {
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [dropdownContent, setDropdownContent] = useState(null);

  const { refs, x, y, strategy } = useFloating({
    placement: 'right-start',
    middleware: [offset(8), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    refs.setReference(buttonRef.current);
  }, [refs]);

  useEffect(() => {
    refs.setFloating(dropdownContent);
  }, [refs, dropdownContent]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setOpenDropdown(null);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setOpenDropdown]);

  // Utility function to truncate address
  const truncateAddress = (address) => {
    if (address.length <= 12) return address;
    return `${address.slice(0, 7)}...${address.slice(-5)}`;
  };

  const handleToggle = () => {
    if (isOpen) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(title);
    }
    playClickSound();
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Main Dropdown Button */}
      <button
        ref={buttonRef}
        className={`w-full flex justify-between items-center text-lg font-medium hover:text-lm-orange transition-colors focus:outline-none`}
        onClick={handleToggle}
        onMouseEnter={playHoverSound}
        aria-expanded={isOpen}
        aria-controls={`dropdown-${title}`}
      >
        {title} <FiChevronDown />
      </button>

      {/* Mobile Dropdown */}
      <div className="sm:hidden">
        {isOpen && (
          <div>
            {/* Header within Dropdown */}
            <div className="px-4 py-2 bg-lm-medium-gray">
              <h3 className="text-lg font-semibold">{title}</h3>
            </div>
            <ul className="mt-2 space-y-2 bg-lm-medium-gray shadow-lg rounded-none transition-all duration-300 ease-in-out">
              {items.map((item) => (
                <li key={item.name}>
                  {item.action === 'copy' ? (
                    <button
                      className={`flex items-center space-x-2 w-full px-4 py-2 hover:bg-gray-700 transition-colors focus:outline-none`}
                      onClick={() => {
                        copyToClipboard(item.value);
                        playClickSound();
                      }}
                      onMouseEnter={playHoverSound}
                    >
                      <span>{truncateAddress(item.name)}</span>
                      <FaCopy size={14} />
                    </button>
                  ) : item.subItems ? (
                    // Delegate rendering to NestedDropdown
                    <NestedDropdown
                      title={item.name}
                      items={item.subItems}
                      playHoverSound={playHoverSound}
                      playClickSound={playClickSound}
                      copyToClipboard={copyToClipboard}
                      isMobile={true}
                      isDesktop={false}
                    />
                  ) : (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`flex items-center space-x-2 w-full px-4 py-2 hover:bg-gray-700 rounded-none transition-colors`}
                      onMouseEnter={playHoverSound}
                      onClick={playClickSound}
                    >
                      <span>{item.name}</span>
                      {item.icon && item.icon}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Desktop Popup */}
      <div className="hidden sm:block">
        {isOpen && (
          <div
            ref={setDropdownContent}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: '16rem',
            }}
            className="bg-lm-dark-gray shadow-lg border-4 border-dashed border-lm-orange rounded-none z-40"
          >
            {/* Header within Dropdown */}
            <div className="px-4 py-2 bg-lm-dark-gray border-b border-gray-700">
              <h3 className="text-lg font-semibold text-lm-yellow">{title}</h3>
            </div>
            <ul className="py-2">
              {items.map((item) => (
                <li key={item.name}>
                  {item.action === 'copy' ? (
                    <button
                      className={`flex justify-between items-center w-full px-4 py-2 hover:bg-gray-700 transition-colors focus:outline-none`}
                      onClick={() => {
                        copyToClipboard(item.value);
                        playClickSound();
                      }}
                      onMouseEnter={playHoverSound}
                    >
                      <span>{truncateAddress(item.name)}</span>
                      <FaCopy size={14} />
                    </button>
                  ) : item.subItems ? (
                    // Delegate rendering to NestedDropdown
                    <NestedDropdown
                      title={item.name}
                      items={item.subItems}
                      playHoverSound={playHoverSound}
                      playClickSound={playClickSound}
                      copyToClipboard={copyToClipboard}
                      isDesktop={true}
                      isMobile={false}
                    />
                  ) : (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`flex justify-between items-center w-full px-4 py-2 hover:bg-gray-700 transition-colors`}
                      onMouseEnter={playHoverSound}
                      onClick={playClickSound}
                    >
                      <span>{item.name}</span>
                      {item.icon && item.icon}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  playHoverSound: PropTypes.func.isRequired,
  playClickSound: PropTypes.func.isRequired,
  copyToClipboard: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpenDropdown: PropTypes.func.isRequired,
};

export default Dropdown;
