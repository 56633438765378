// src/components/ComingSoonItem.js

import React from 'react';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'; // Import the CSS for tooltips

const ComingSoonItem = ({
  name,
  info,
  playHoverSound,
  playClickSound,
}) => {
  // Function to handle info icon click (optional, as tooltip is shown on hover)
  const handleInfoClick = () => {
    playClickSound();
  };

  return (
    <div className="flex items-center space-x-2 cursor-custom"> {/* Apply custom cursor here if desired */}
      {/* Project Name */}
      <span
        className="text-lg font-medium hover:text-lm-orange transition-colors cursor-pointer"
        onMouseEnter={playHoverSound}
        onClick={playClickSound}
      >
        {name}
      </span>
      
      {/* "Coming Soon" Label */}
      <span className="text-sm text-red-500 font-semibold">Coming Soon</span>
      
      {/* Info Icon with Tooltip */}
      <button
        className="focus:outline-none"
        onMouseEnter={playHoverSound}
        onClick={handleInfoClick}
        aria-label={`More information about ${name}`}
        data-tooltip-id={`tooltip-${name}`}
        data-tooltip-place="top"
      >
        <FaInfoCircle className="text-red-500 hover:text-red-700" />
      </button>
      
      {/* Tooltip */}
      <Tooltip
        id={`tooltip-${name}`}
        className="max-w-xs bg-gray-800 text-white pointer-events-auto" // Solid background and white text
        interactive={true} // Make tooltip interactive
        delayHide={300} // Adjust delay to prevent premature hiding
        zIndex={50} // Ensure tooltip is on top
        clickable
        render={() => (
          <div>
            {name === "D.P.P" ? (
              <>
                {info}
                <div className="mt-2">
                  <a
                    href="https://x.com/SmooviePhone/status/1860096064428163495"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-lm-yellow underline"
                  >
                    Read more...
                  </a>
                </div>
              </>
            ) : (
              info
            )}
          </div>
        )}
      />
    </div>
  );
};

ComingSoonItem.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.node.isRequired,
  playHoverSound: PropTypes.func.isRequired,
  playClickSound: PropTypes.func.isRequired,
};

export default ComingSoonItem;
