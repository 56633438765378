// src/components/SimpleItem.js

import React from 'react';
import PropTypes from 'prop-types';

const SimpleItem = ({
  name,
  link,
  playHoverSound,
  playClickSound,
}) => {

  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={`text-lg font-medium hover:text-lm-orange transition-colors cursor-pointer hover:bg-gray-700`}
      onMouseEnter={playHoverSound}
      onClick={playClickSound}
    >
      {name}
    </a>
  );
};

SimpleItem.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  playHoverSound: PropTypes.func.isRequired,
  playClickSound: PropTypes.func.isRequired,
};

export default SimpleItem;
