// src/components/NestedDropdown.js

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { FaCopy } from 'react-icons/fa';
import { useFloating, offset, flip, shift, autoUpdate } from '@floating-ui/react-dom';

const NestedDropdown = ({
  title,
  items,
  playHoverSound,
  playClickSound,
  copyToClipboard,
  isMobile = false,
  isDesktop = false,
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [submenuContent, setSubmenuContent] = useState(null);

  const { refs, x, y, strategy } = useFloating({
    placement: 'right-start',
    middleware: [offset(8), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    if (isDesktop) {
      refs.setReference(buttonRef.current);
      refs.setFloating(submenuContent);
    }
  }, [refs, submenuContent, isDesktop]);

  // Close submenu when clicking outside (Desktop Only)
  useEffect(() => {
    if (!isDesktop) return;

    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, isDesktop]);

  // Function to toggle submenu
  const handleToggle = () => {
    setOpen(!open);
    playClickSound();
  };

  // Utility function to truncate address
  const truncateAddress = (address) => {
    if (address.length <= 12) return address;
    return `${address.slice(0, 7)}...${address.slice(-5)}`;
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Button to toggle submenu */}
      <button
        ref={buttonRef}
        className={`flex justify-between items-center w-full px-4 py-2 hover:bg-gray-700 transition-colors focus:outline-none`}
        onClick={handleToggle}
        onMouseEnter={playHoverSound}
        aria-expanded={open}
        aria-controls={`nested-dropdown-${title}`}
      >
        <span>{title}</span>
        {isMobile ? <FiChevronDown /> : <FiChevronRight />}
      </button>

      {/* Submenu Rendering */}
      {isMobile ? (
        // Mobile: Inline collapsible submenu
        open && (
          <div>
            {/* Header within NestedDropdown */}
            <div className="px-4 py-2 bg-lm-medium-gray">
              <h4 className="text-md font-semibold">{title}</h4>
            </div>
            <ul className="mt-2 space-y-2 bg-lm-medium-gray shadow-lg rounded-none transition-all duration-300 ease-in-out">
              {items.map((item) => (
                <li key={item.name}>
                  {item.action === 'copy' ? (
                    <button
                      className={`flex items-center space-x-2 w-full px-4 py-2 hover:bg-gray-700 transition-colors focus:outline-none`}
                      onClick={() => {
                        copyToClipboard(item.value);
                        playClickSound();
                      }}
                      onMouseEnter={playHoverSound}
                    >
                      <span>{truncateAddress(item.name)}</span>
                      <FaCopy size={14} />
                    </button>
                  ) : item.subItems ? (
                    // Recursive NestedDropdown for deeper levels
                    <NestedDropdown
                      title={item.name}
                      items={item.subItems}
                      playHoverSound={playHoverSound}
                      playClickSound={playClickSound}
                      copyToClipboard={copyToClipboard}
                      isMobile={true}
                      isDesktop={false}
                    />
                  ) : (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`flex items-center space-x-2 w-full px-4 py-2 hover:bg-gray-700 transition-colors`}
                      onMouseEnter={playHoverSound}
                      onClick={playClickSound}
                    >
                      <span>{item.name}</span>
                      {item.icon && item.icon}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )
      ) : (
        // Desktop: Floating submenu
        open && (
          <div
            ref={setSubmenuContent}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: '16rem',
            }}
            className="bg-lm-dark-gray shadow-lg border-4 border-dashed border-lm-orange rounded-none z-40"
          >
            {/* Header within NestedDropdown */}
            <div className="px-4 py-2 bg-lm-dark-gray border-b border-gray-700">
              <h4 className="text-md font-semibold text-lm-yellow">{title}</h4>
            </div>
            <ul className="py-2">
              {items.map((item) => (
                <li key={item.name}>
                  {item.action === 'copy' ? (
                    <button
                      className={`flex justify-between items-center w-full px-4 py-2 hover:bg-gray-700 transition-colors focus:outline-none`}
                      onClick={() => {
                        copyToClipboard(item.value);
                        playClickSound();
                      }}
                      onMouseEnter={playHoverSound}
                    >
                      <span>{truncateAddress(item.name)}</span>
                      <FaCopy size={14} />
                    </button>
                  ) : item.subItems ? (
                    <>
                      <button
                        className={`flex justify-between items-center w-full px-4 py-2 hover:bg-gray-700 transition-colors focus:outline-none`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event bubbling
                          playClickSound();
                        }}
                        onMouseEnter={playHoverSound}
                      >
                        <span>{item.name}</span>
                        <FiChevronRight />
                      </button>
                      {/* Submenu */}
                      <NestedDropdown
                        title={item.name}
                        items={item.subItems}
                        playHoverSound={playHoverSound}
                        playClickSound={playClickSound}
                        copyToClipboard={copyToClipboard}
                        isDesktop={true}
                        isMobile={false}
                      />
                    </>
                  ) : (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`flex justify-between items-center w-full px-4 py-2 hover:bg-gray-700 transition-colors`}
                      onMouseEnter={playHoverSound}
                      onClick={playClickSound}
                    >
                      <span>{item.name}</span>
                      {item.icon && item.icon}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )
      )}
    </div>
  );
};

NestedDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  playHoverSound: PropTypes.func.isRequired,
  playClickSound: PropTypes.func.isRequired,
  copyToClipboard: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

NestedDropdown.defaultProps = {
  isMobile: false,
  isDesktop: false,
};

export default NestedDropdown;
