// src/components/ProjectItem.js

import React from 'react';
import PropTypes from 'prop-types';
import { FaCopy } from 'react-icons/fa';
import NestedDropdown from './NestedDropdown';
import ComingSoonItem from './ComingSoonItem';

const ProjectItem = ({
  name,
  items,
  comingSoon,
  info,
  playHoverSound,
  playClickSound,
  copyToClipboard,
}) => {
  if (comingSoon) {
    return (
      <ComingSoonItem
        name={name}
        info={info}
        playHoverSound={playHoverSound}
        playClickSound={playClickSound}
      />
    );
  }

  return (
    <div>
      <h2
        className={`text-lg font-medium hover:text-lm-orange transition-colors cursor-pointer`}
        onMouseEnter={playHoverSound}
        onClick={playClickSound}
      >
        {name}
      </h2>
      <ul className="mt-2 ml-4 space-y-2">
        {items.map((item) => (
          <li key={item.name}>
            {/* Prioritize 'action="copy"' over 'subItems' */}
            {item.action === 'copy' ? (
              <button
                className={`flex items-center space-x-2 w-full px-4 py-2 hover:bg-gray-700 transition-colors focus:outline-none`}
                onClick={() => {
                  copyToClipboard(item.value);
                  playClickSound();
                }}
                onMouseEnter={playHoverSound}
              >
                <span>{truncateAddress(item.name)}</span>
                <FaCopy size={14} />
              </button>
            ) : item.subItems ? (
              <NestedDropdown
                title={item.name}
                items={item.subItems}
                playHoverSound={playHoverSound}
                playClickSound={playClickSound}
                copyToClipboard={copyToClipboard}
                isMobile={false}
                isDesktop={false}
              />
            ) : (
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`flex items-center space-x-2 w-full px-4 py-2 hover:bg-gray-700 transition-colors`}
                onMouseEnter={playHoverSound}
                onClick={playClickSound}
              >
                <span>{item.name}</span>
                {item.icon && item.icon}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

ProjectItem.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  comingSoon: PropTypes.bool,
  info: PropTypes.node,
  playHoverSound: PropTypes.func.isRequired,
  playClickSound: PropTypes.func.isRequired,
  copyToClipboard: PropTypes.func.isRequired,
};

ProjectItem.defaultProps = {
  comingSoon: false,
  info: null,
};

// Utility function to truncate address
const truncateAddress = (address) => {
  if (address.length <= 12) return address;
  return `${address.slice(0, 7)}...${address.slice(-5)}`;
};

export default ProjectItem;
