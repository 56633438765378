// src/components/Typewriter.js

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Typewriter = React.memo(({ text, speed = 150, cursor = '|' }) => {
  const [displayedText, setDisplayedText] = useState('');
  const currentIndex = useRef(0); // Use ref to store current index
  const timeouts = useRef([]); // Store timeout IDs

  useEffect(() => {
    // Reset state and index when text or speed changes
    currentIndex.current = 0;
    setDisplayedText('');
    timeouts.current = [];

    // Recursive function to type each character
    const type = () => {
      if (currentIndex.current < text.length) {
        const nextChar = text.charAt(currentIndex.current);
        console.log(`Appending character: '${nextChar}' at index ${currentIndex.current}`);
        setDisplayedText((prev) => prev + nextChar);
        currentIndex.current += 1;

        // Schedule the next character
        const timeoutId = setTimeout(type, speed);
        timeouts.current.push(timeoutId);
      }
    };

    // Start typing after the initial delay
    const initialTimeout = setTimeout(type, speed);
    timeouts.current.push(initialTimeout);

    // Cleanup function to clear all timeouts when component unmounts or dependencies change
    return () => {
      timeouts.current.forEach((timeoutId) => clearTimeout(timeoutId));
      timeouts.current = [];
    };
  }, [text, speed]);

  return (
    <span className="inline-flex items-center">
      {displayedText}
      <span className="ml-1 animate-blink">
        {cursor}
      </span>
    </span>
  );
});

Typewriter.propTypes = {
  text: PropTypes.string.isRequired, // Ensure text is a string
  speed: PropTypes.number,
  cursor: PropTypes.string,
};

export default Typewriter;
