// src/data/Projects.js

import { FaShoppingCart } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

const projects = [
  {
    name: "SmooviePhone ($SP)",
    dropdown: true,
    items: [
      { name: "Official", link: "https://x.com/SmooviePhone", icon: <FaXTwitter />, },
      { name: "Stake $SP", link: "https://www.smooviephonestaking.com/stake" },
      { name: "Instant Bridge $SP (Stargate)", link: "https://stargate.finance/bridge?srcChain=sanko&srcToken=0x3f710cBd0F4268719C6c2E5E078a4CAfAeED7d45&dstChain=solana&dstToken=E8zSHWdtgJ7pST1QPRDYkrKpnVJNoekZgETYm1yieT72" },
      { name: "Bridge $SP", link: "https://www.smooviephonestaking.com/bridge" },
      {
        name: "Sanko Chain",
        subItems: [
          { name: "0x3f710cbd0f4268719c6c2e5e078a4cafaeed7d45", action: "copy", value: "0x3f710cbd0f4268719c6c2e5e078a4cafaeed7d45" },
          { name: "Defined Chart", link: "https://www.defined.fi/sanko/0x18927808878135db182151c69a98aa6fd40831f9?quoteToken=token0" },
          { name: "Buy On Camelot", link: "https://app.camelot.exchange/?inputCurrency=0x754cdad6f5821077d6915004be2ce05f93d176f8&outputCurrency=0x3f710cbd0f4268719c6c2e5e078a4cafaeed7d45", icon: <FaShoppingCart /> },
        ],
      },
      {
        name: "Solana Chain",
        subItems: [
          { name: "E8zSHWdtgJ7pST1QPRDYkrKpnVJNoekZgETYm1yieT72", action: "copy", value: "E8zSHWdtgJ7pST1QPRDYkrKpnVJNoekZgETYm1yieT72" },
          { name: "Dexscreener Chart", link: "https://dexscreener.com/solana/7d2gfqf9sbbkyxrynoaktf65184ubgvktjpl6itygtmb" },
          { name: "Buy on Raydium", link: "https://raydium.io/swap/?inputMint=sol&outputMint=E8zSHWdtgJ7pST1QPRDYkrKpnVJNoekZgETYm1yieT72", icon: <FaShoppingCart /> },
        ],
      },
    ],
  },
  /*{
    name: "Silver Coin ($Silver)",
    dropdown: true,
    items: [
      { name: "0x699bfe71910d14b8560f65d5bd370fc65d0c3b64", action: "copy", value: "0x699bfe71910d14b8560f65d5bd370fc65d0c3b64" },
      { name: "Defined Chart", link: "https://www.defined.fi/sanko/0x5ca8b27f2c603e6249070efbcc0cfb9eec4fe380?quoteToken=token0" },
      { name: "Buy On Camelot", link: "https://app.camelot.exchange/?inputCurrency=0x754cdad6f5821077d6915004be2ce05f93d176f8&outputCurrency=0x5ca8b27f2c603e6249070efbcc0cfb9eec4fe380", icon: <FaShoppingCart /> },
    ],
  },*/
  {
    name: "A.I Agent Kaia ($kAia)",
    dropdown: true,
    items: [
      { name: "Official", link: "https://x.com/AiAgentkAia", icon: <FaXTwitter /> },
      { name: "7bD1RTKSZSsneDXDoMincSswED3uBnzuRyHi5tJ7ZjwN", action: "copy", value: "7bD1RTKSZSsneDXDoMincSswED3uBnzuRyHi5tJ7ZjwN" },
      { name: "Dexscreener Chart", link: "https://dexscreener.com/solana/behrfjj1xpfwdstcseu7sjkdymw9knham11fbgmpgprg" },
      { name: "Buy on Jupiter", link: "https://jup.ag/swap/SOL-7bD1RTKSZSsneDXDoMincSswED3uBnzuRyHi5tJ7ZjwN", icon: <FaShoppingCart /> },
    ],
  },
  {
    name: "Predictions Games",
    dropdown: true,
    items: [
      //{ name: "Ethereum420", link: "http://ethereum420.com/" },
      { name: "Arbitrum420", link: "https://arbitrum420.com/" },
      { name: "Sanko420", link: "https://sanko420.com/" },
      { name: "Base420", link: "https://base420.xyz/" },
      { name: "APE420", link: "https://ape420.xyz/" },
    ],
  },
  {
    name: "Prediction Markets",
    dropdown: true,
    items: [
      { name: "Ethereum Predicts", link: "https://ethereumpredicts.com/" },
      { name: "Arbitrum Predicts", link: "https://arbitrumpredicts.com/" },
      { name: "Sanko Predicts", link: "https://sankopredicts.com/" },
      { name: "APE Predicts", link: "https://apespredict.com/" },
      { name: "Base Predicts", link: "https://basepredicts.com/" },
    ],
  },
  { name: "Leverage Machine", link: "https://www.leveragemachine.xyz/" },
  { name: "P2P Marketplace", link: "https://www.overthecounter.meme/" },
  {
    name: "Games",
    dropdown: true,
    items: [
      { name: "Layer 3 Racing", link: "https://layer3racing.com/" },
      { name: "SNAKO", link: "https://snako.wtf/" },
      { name: "The Speak Easy", link: "https://www.thespeakeasy.fun/" },
    ],
  },
  { name: "NFT $SMS", link: "https://sudoswap.xyz/#/browse/sanko/buy/0xc447597efc493e36ccca16707559fb7238d0367c" },
  {
    name: "The Oasis",
    comingSoon: true,
    info: (
      <div>
        <p className="font-semibold mb-1">The Oasis:</p>
        <p className="mb-2">The Oasis is a 3D interactive universe where $SP holders can interact ,chat and explore the Smoovie Phone ecosystem.</p>
      </div>
    ),
  },
  {
    name: "D.P.P",
    comingSoon: true,
    info: (
      <div>
        <p className="font-semibold mb-1">D.P.P (Decentralized Parlay Platform):</p>
        <p>A crypto-powered betting platform where you can:</p>
        <ul className="list-disc list-inside mt-1">
          <li>Bet with any token and win big with parlay sheets.</li>
          <li>Trade bets as NFTs on OverTheCounter.meme.</li>
          <li>Be the bank by sharing in platform profits.</li>
          <li>Powered by AI assistant kAia for fair resolutions and live support.</li>
        </ul>
      </div>
    ),
  },
  { name: "Smoovie Phone Docs", link: "https://smoovie-phone-usdsp-defi-x-gamef.gitbook.io/smoovie-phone-usdsp-defi-x-gamefi/getting-started/quickstart#id-12.-contact-and-community-links" },
];

export default projects;
